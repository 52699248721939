import * as React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentHaederHome  from "../components/contentheaderhome"

import affilips from "../images/logo/affilips-new.png"
import wngroup from "../images/logo/wngroup.png"
import sweco from "../images/logo/sweco.png"
import denys from "../images/logo/denys.png"
import deme from "../images/logo/deme_2.jpg"
import febe from "../images/logo/febe.jpg"
import bb from "../images/logo/bb.png"
import melexis from "../images/logo/melexis.png"
import zigzag from "../images/logo/zigzag.png"
import elia from "../images/logo/elia.png"
import axa from "../images/logo/axa.svg"
import huawei from "../images/logo/huawei.png"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    {/* <Helmet>
      <script async src="https://tally.so/widgets/embed.js"></script>
      <script>{`
        window.TallyConfig = {
          "formId": "meqBL0",
          "popup": {
            "open": {
              "trigger": "scroll",
              "scrollPercent": 60
            },
            "alignLeft": true
          }
        };`}
      </script>
    </Helmet> */}
      {/* <div className="alert alert-warning">
        < strong>INFO </strong> FEARS 2023 will be held on October 26th. Save your calendar:&nbsp;
        <a href={"https://outlook.office.com/calendar/action/compose?allday=false&body=The%2021st%20Faculty%20of%20Engineering%20and%20Architecture%20Research%20Symposium.%20All%20information%20and%20registration%20on%20fears.ugent.be.%20%0A%0ASubmission%20deadline%3A%20September%2025&enddt=2023-10-26T16%3A00%3A00%2B00%3A00&location=Sint-Pietersabdij&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-10-26T11%3A00%3A00%2B00%3A00&subject=FEARS%202023"}>Outlook</a>&nbsp;/&nbsp;
        <a href={"https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20231026T110000Z%2F20231026T160000Z&details=The%2021st%20Faculty%20of%20Engineering%20and%20Architecture%20Research%20Symposium.%20All%20information%20and%20registration%20on%20fears.ugent.be.%20%0A%0ASubmission%20deadline%3A%20September%2025&location=Sint-Pietersabdij&text=FEARS%202023"}>Google</a>&nbsp;/&nbsp;
        <a href={"/FEARS.ics"}>Others (ICS)</a>
      </div> */}
      {/* <div className="alert alert-warning"> */}
      {/* <strong>Thank you for attending FEARS 🎓</strong> Over 100 researchers presented their work, and more than 200 visitors shared ideas at FEARS 2023 (<Link to="/pictures/">pictures</Link>). Proceedings will be online soon. */}
      {/* <strong>Online registrations closed</strong> 🎟️ Last-minute registration is only possible at the on-site registration booth. */}
      {/* < strong>INFO </strong> Only a few seats left at the roundtable discussions. <a href="https://evan.ugent.be/r/fears2023/" target="_blank" rel="noopener noreferrer" activeClassName="active">Register now</a> and reserve your spot! Extended registration deadline: Monday `<strong>October 23rd</strong> */}
      {/* </div> */}
    <ContentHaederHome>
      <h2>FEARS is the premier meeting place for researchers at the Faculty of Engineering and Architecture.</h2>

      <p><a href="https://wurtl.ugent.be/c/1/fears2024">Register here!</a></p>

      <p>At the 2024 FEA Research Symposium you can expect:</p>
      <ul className="mbottom-small">
        <li>a variety of <strong>scientific sessions</strong> to present your work and discover the research of your peers.</li>
        <li>enjoy yourself and get to know your colleagues during the <strong>coffee breaks and reception</strong> with food and drinks.</li>
        <li>discover (applied) research in <strong>industry</strong> during one of the <strong>roundtable</strong> sessions.</li>
        <li>become an active participant and receive <strong>doctoral school credits</strong>.</li>
        <li>(MSc Students) discover why you should start a PhD during the <strong>PhD info session</strong>.</li>
      </ul>

      <p>The venue for this event is the <a href="https://goo.gl/maps/MdQqxc773pLcQPJ17" target="_blank" rel="noopener noreferrer">Sint-Pietersabdij</a> in the city center of Ghent.
      Discover the <Link to="/program/">full program</Link> with posters, pitches, roundtables and more.
      <strong> We welcome you at 13:00 </strong>(<Link to="/program/">full program</Link>)
      {/* Registration deadline extended to Monday <strong>October 23rd</strong>: <a href="https://evan.ugent.be/r/fears2023/" target="_blank" rel="noopener noreferrer" activeClassName="active"><strong>🎟️ register now</strong></a>  */}
      {/* <strong>Online registrations closed</strong>: last-minute registration is only possible at the on-site registration booth. */}
      </p>
    </ContentHaederHome>

    <div className="row homepage-blocks">
      <div className="col-xs-12 col-sm-8 mbottom-default">

        <div className="row">

          <div className="col-xs-12 pbottom-default">
          <div className="row">
              <div className="col-sm-6">

                <h2>Why participate?</h2>
                <p>
                  FEARS is the ideal place to meet your fellow FEA researchers and discover their work. There is no doubt that there are colleagues working in the faculty that you can learn from or even collaborate with &#8211; even though you might not yet have met them. It is also a unique opportunity to gain visibility in the entire faculty.
                </p>
                <p>
                  FEARS is also a great opportunity to practice your presentation skills. At the event, you will have a large audience from various backgrounds who will often asked insightful questions. FEARS even provides training to help you prepare for your poster, pitch, or industry roundtable, which can earn you credit towards the Doctoral Schools.
                </p>
              </div>
              <div className="col-sm-6">

                <h2>Who is invited?</h2>
                <p>
                  Everyone! Seriously, all (affiliated) staff of the UGent Faculty of Engineering and Architecture are invited! The event is free of charge for all FEA members, and you are also welcome to join without presenting.
                </p>
                <p>
                  In addition, FEA students are warmly invited to discover the exciting research happening in our faculty. There is no place like FEARS to learn what engineering research really is about. You will discover what amazing research is actually done by the same professors and researchers that are also teaching you. Attending will give you a head start in finding your Master's thesis topic or a career in research.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <h2>Who is organizing FEARS?</h2>
                <p>
                  FEARS is organized by a motivated team of PhD and Post-Doc researchers of the Faculty of Engineering and Architecture.
                  A standing committee of professors and support staff provides guidance and support for long-term continuity.
                </p>
              </div>
              <div className="col-sm-8">
                <h3>Organizing committee</h3>
                <li>Chair: Melanie Verstraete</li>
                <li>Program: Angelos Michalopoulos, Meryem Ben Elajla, Thomas Tarnaud, Sajid Qazi </li>
                <li>IT: Ruben Dedecker, Ieben Smessaert</li>
                <li>Company interaction: Maarten de Mildt</li>
                <li>Awards: Bruno Vanderschelden</li>
                <li>Venue: Peggy De Smet </li>
                <li>Communications: Preston Donaldson, Ferre Vanden Kerchove, Anke Blommaert, Harindra Kannojia</li>
              </div>

              <div className="col-sm-4">
              <h3>Standing committee</h3>
              <p>
                Prof. Nathan Van Den Bossche, dr. Ljiljana Platisa, prof. Femke De Backere, Prof. Herbert De Smet (<a href="https://www.ugent.be/ea/nl/faculteit/raden/pr-commissie">FEA PR</a>), Sabine Wittevrongel (<a href="https://www.ugent.be/ea/nl/faculteit/raden/cwo.htm">FEA CWO</a>), Prof. Maarten Liefooghe, Jeroen Ongenae, Peggy De Smet
              </p>

              </div>
            </div>
          </div>

          {/* <div className="col-xs-12 pbottom-default">
            <div className="row">
              <div className="col-sm-8">
                <h2 className="h2 mbottom-small">Organizing committee</h2>
                FEARS is organized by a team of professors, PhD researchers, Postdocs and support staff. We are still looking for extra helping hands.
                Something for you? Join our next Organizing meeting. On top of this can active particitpation be includes a a transferable skill in your doctoral school.

                <div className="clearfix mbottom-small">
                  <iframe
                    src="https://tally.so/embed/3EkWv4?alignLeft=1&hideTitle=1&transparentBackground=1"
                    width="100%"
                    height="220"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0"
                    title="FEARS Organizing committee">
                  </iframe>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="col-xs-12 col-sm-4">
        <h2 className="mbottom-small">Timeline</h2>
        <ul className="linklist-events list-unstyled">
          
          <li>
            <div className="block">
              <div className="date-container">
                <span className="month"><strike>Nov</strike></span>
                <span className="day"><strike>8</strike></span>
              </div>

              <div className="content-container">
                <p className="h3">
                  <span><strike>submission deadline</strike></span>
                </p><strike>
                Closing of the <a href="https://wurtl.ugent.be/c/1/fears2024">submission form</a>
                </strike><p className="date ellipsis"></p>
              </div>
            </div>
          </li>

          <li>
            <div className="block">
              <div className="date-container">
                <span className="month">Nov</span>
                <span className="day">8</span>
              </div>

              <div className="content-container">
                <p className="h3">
                  <span>Extended submission deadline</span>
                </p>
                Closing of the <a href="https://wurtl.ugent.be/c/1/fears2024">submission form</a>
                <p className="date ellipsis"></p>
              </div>
            </div>
          </li>

          <li>
            <div className="block">
              <div className="date-container">
                <span className="month">Nov</span>
                <span className="day">13</span>
              </div>

              <div className="content-container">
                <p className="h3">
                  <span>Revision deadline</span>
                </p>
                  Sending of email with revision result
                <p className="date ellipsis"></p>
              </div>
            </div>
          </li>

          <li>
            <div className="block">
              <div className="date-container">
                <span className="month">Nov</span>
                <span className="day">20</span>
              </div>

              <div className="content-container">
                <p className="h3">
                  <span>Resubmission deadline</span>
                </p>
                  Submission deadline for revised work
                <p className="date ellipsis"></p>
              </div>
            </div>
          </li>
          {/* <li>
            <div className="block">
              <div className="date-container">
                <span className="month">Oct</span>
                <span className="day">2</span>
              </div>

              <div className="content-container">
                <p className="h3">
                  <span>Extended submission deadline</span>
                </p>
                <strike>Closing of the <a href="https://wurtl.ugent.be/c/1/fears2024">submission form</a></strike>
                <p className="date ellipsis"></p>
              </div>
            </div>
          </li> */}
          {/* <li>
            <div className="block">
              <div className="date-container">
                <span className="month">Oct</span>
                <span className="day">11</span>
              </div>

              <div className="content-container">
                <p className="h3">
                  <span>Acceptance date</span>
                </p>
                Final date you will receive feedback on your submission.
                <p className="date ellipsis"></p>
              </div>
            </div>
          </li> */}
          {/* <li>
            <div className="block">
              <div className="date-container">
                <span className="month">Oct</span>
                <span className="day">23</span>
              </div>

              <div className="content-container">
                <p className="h3">
                  <span>Extended Registration deadline</span>
                </p>
                <strike>Closing of the <a href="https://evan.ugent.be/r/fears2023/">registration form</a></strike>
                <p className="date ellipsis"></p>
              </div>
            </div>
          </li> */}
          <li>
            <div className="block">
              <div className="date-container">
                <span className="month">Nov</span>
                <span className="day">27</span>
              </div>

              <div className="content-container">
                <p className="h3">
                  <span>FEARS 2024</span>
                </p>
                {/* Save the date! */}
                {/* Save to your calender:&nbsp;
                <a href={"https://outlook.office.com/calendar/action/compose?allday=false&body=The%2021st%20Faculty%20of%20Engineering%20and%20Architecture%20Research%20Symposium.%20All%20information%20and%20registration%20on%20fears.ugent.be.%20%0A%0ASubmission%20deadline%3A%20September%2025&enddt=2023-10-26T16%3A00%3A00%2B00%3A00&location=Sint-Pietersabdij&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-10-26T11%3A00%3A00%2B00%3A00&subject=FEARS%202023"}>Outlook</a>&nbsp;/&nbsp;
                <a href={"https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20231026T110000Z%2F20231026T160000Z&details=The%2021st%20Faculty%20of%20Engineering%20and%20Architecture%20Research%20Symposium.%20All%20information%20and%20registration%20on%20fears.ugent.be.%20%0A%0ASubmission%20deadline%3A%20September%2025&location=Sint-Pietersabdij&text=FEARS%202023"}>Google</a>&nbsp;/&nbsp;
                <a href={"/FEARS.ics"}>Others (ICS)</a> */}
                We welcome you at 13:00 (<Link to="/program/">full program</Link>)
                <p className="date ellipsis"></p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="cell col-xs-12 col-sm-12 mbottom-default">
        <h2 className="h2 mbottom-small">Sponsors</h2>
        <div className="cell"  style={{display: "flex", "flex-wrap": "wrap", "align-self": "center", "align-items": "center", gap: "40px"}}>
          {/* <a style={{border: "none"}} href="https://melexis.be/" target="_blank" rel="noopener noreferrer"><img width={140} src={melexis} alt="melexis"></img></a> */}
          {/* <a style={{border: "none"}} href="https://www.willynaessens.be/" target="_blank" rel="noopener noreferrer"><img width={140} src={wngroup} alt="willy naesens"></img></a> */}
          {/* <a style={{border: "none"}} href="https://www.swecobelgium.be/" target="_blank" rel="noopener noreferrer"><img width={130} src={sweco} alt="sweco"></img></a> */}
          {/* <a style={{border: "none"}} href="https://www.febe.be/" target="_blank" rel="noopener noreferrer"><img width={50} src={febe} alt="febe"></img></a> */}
          {/* <a style={{border: "none"}} href="https://www.affilips.com/" target="_blank" rel="noopener noreferrer"><img width={120} src={affilips} alt="affilips"></img></a> */}
          {/* <a style={{border: "none"}} href="https://b-b.be/" target="_blank" rel="noopener noreferrer"><img width={120} src={bb} alt="bb"></img></a> */}
          <a style={{border: "none"}} href="https://denys.com/" target="_blank" rel="noopener noreferrer"><img width={140} src={denys} alt="denys"></img></a>
          <a style={{border: "none"}} href="https://www.deme-group.com/" target="_blank" rel="noopener noreferrer"><img width={140} src={deme} alt="deme"></img></a>
          <a style={{border: "none"}} href="https://www.elia.be" target="_blank" rel="noopener noreferrer"><img width={140} src={elia} alt="elia"></img></a>
          {/* <a style={{border: "none"}} href="https://ziggzagg.be/" target="_blank" rel="noopener noreferrer"><img width={140} src={zigzag} alt="ziggzagg"></img></a> */}
          <a style={{border: "none"}} href="https://www.axa.be" target="_blank" rel="noopener noreferrer"><img width={70} src={axa} alt="axa"></img></a>
          <a style={{border: "none"}} href="https://www.huawei.com" target="_blank" rel="noopener noreferrer"><img width={70} src={huawei} alt="huawei"></img></a>

        </div>
      </div>

    </div>


  </Layout>
)

export default IndexPage
